import Start from "../button/start";
import Stop from "../button/stop";
import Devices from "../devices";
import Light from "../button/light";
import React from "react";

/**
 *
 * @param canvasVisible
 * @param startScanHandler
 * @param stopScanHandler
 * @param setVideoDeviceId
 * @param torchAvailable
 * @returns {JSX.Element}
 * @constructor
 */
export default function Footer({ canvasVisible, startScanHandler, stopScanHandler, setVideoDeviceId, torchAvailable })
{
    return(
        <>
            <div className="modal-footer justify-content-center">
                {
                    canvasVisible ?
                    <>
                    <div className={'mb-3 w-100'}>
                        <Devices setVideoDeviceId={setVideoDeviceId} />
                    </div>
                    <Light available={torchAvailable} />
                    <Stop stopScanHandler={stopScanHandler} modal={true} />
                    </>
                    :
                    <Start startScanHandler={startScanHandler} toggleModal={false} />
                }
            </div>
        </>
    );
}