import React, {useState} from "react";
import Spinner from './spinner/button';

/**
 *
 * @param outline
 * @param color
 * @param callback
 * @param text
 * @param hideTextOnMobile
 * @param disable
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
export default function Button ({ id, outline, color, callback, text, hideTextOnMobile, submitOnlyOnce, children }) {
    // init css
    const css = ' btn-' + (outline ? 'outline-' : '') + (color ?? '');

    // init states
    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(false);

    /**
     *
     * @param event
     * @returns {Promise<void>}
     */
    async function click(event) {
        let result = false;

        // validate the form-data
        const form = event.target.closest('form');
        if(!form.noValidate && !form.checkValidity()) {
            result = form.reportValidity();
        } else {
            setDisabled(true);
            setLoading(true);

            result = await callback(event);

            setLoading(false);

            setDisabled(submitOnlyOnce && result);
        }

        return result;
    }

    return (
        <>
            <button id={id} data-test={id} className={"form-control btn" + css} disabled={disabled} onClick={click} aria-label={text}>
                <span className={(hideTextOnMobile ? ('d-none d-md-inline me-md-2') : 'me-2')}>{text}</span>
                {loading ? <Spinner /> : (children ?? '')}
            </button>
        </>
    );
}