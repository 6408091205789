import { Dispatch, SetStateAction, useEffect, useState } from "react";

/**
 *
 */
type ReturnType = [string, Dispatch<SetStateAction<string>>];

export enum LOCAL_STORAGE_STATUS {
    NOT_INITIALIZED = 'LOCAL_STORAGE_NOT_INITIALIZED'
}

/**
 *
 * @param localStorageKey
 * @param defaultValue
 */
export default function useStateWithLocalStorage(
    localStorageKey: string, // Needs to be unique
    defaultValue: string
): ReturnType {
    const key = 'eqno-' + localStorageKey;
    const [value, setValue] = useState(LOCAL_STORAGE_STATUS.NOT_INITIALIZED.toString());

    // on init
    useEffect(() => {
        setValue(localStorage.getItem(key) ?? defaultValue);
    }, [defaultValue, key]);

    // on change
    useEffect(() => {
        if(LOCAL_STORAGE_STATUS.NOT_INITIALIZED.toString() !== value)
            localStorage.setItem(key, value);
    }, [value, key, defaultValue]);

    return [value, setValue];
}