import React from "react";
import Badge from "./badge";

/**
 *
 */
export type IngredientTypeData = {
    id : string
    name : string
    translation : string
    trafficLight : string
    description : string
};

/**
 *
 */
type Data = {
    id : string
    ingredientType : IngredientTypeData
};

/**
 *
 * @param ingredient
 * @constructor
 */
export default function IngredientType ({ id, ingredientType } : Data) {
    // init
    const placement = 'bottom';

    // render 
    return (
        <Badge
            id={'badge-' + ingredientType.name + '-' + id}
            type={ingredientType.trafficLight}
            value={ingredientType.translation}
            tooltip={{title: ingredientType.description, content: '', placement: placement}}
        />
    );
}