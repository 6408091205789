import React from "react";
import {useTranslation} from "next-i18next";
import InsertHTML from "../insertHTML";

/**
 *
 * @param message
 * @param allowHTML
 * @returns {JSX.Element}
 * @constructor
 */
export default function Error ({ message, allowHTML = false }) {
    const { t } = useTranslation();

    return (
        <>
            <div className="d-flex alert alert-danger alert-dismissible fade show align-items-center mt-3" role="alert">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                     className="bi bi-exclamation-triangle-fill flex-shrink-0 me-3" viewBox="0 0 16 16" role="img"
                     aria-label="Warning:">
                    <path
                        d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                </svg>
                {allowHTML
                    ? <InsertHTML content={'<strong>' + t('error') + ':</strong> ' + message} style={''} />
                    : <div className={'d-inline'}><strong>{t('error')}:</strong> {message}</div>
                }
                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" />
            </div>
        </>
    );
}