import React from "react";
import IngredientType , {IngredientTypeData} from "./ingredientType";
import {useTranslation} from "next-i18next";

/**
 *
 */
type Data = {
    id : string
    singular : boolean
    ingredientTypes : IngredientTypeData[]
};

/**
 *
 * @param ingredients
 * @constructor
 */
export default function IngredientTypeList ({ id, singular, ingredientTypes } : Data)  {
    // init
    const { t } = useTranslation();
    const defaultDescription = t('no.more.information', { ns: 'glossary' });

    // init the description of the ingredients
    let vegan = false;
    let vegetarianIndex = -1;
    ingredientTypes.map((ingredientType, index) => {
        vegan = 'vegan' === ingredientType.name ? true : vegan;
        vegetarianIndex = 'vegetarian' === ingredientType.name ? index : vegetarianIndex;
        const translationIndex = ingredientType.name + '.lead';
        const description = t(translationIndex, { ns: 'ingredient-types' });
        ingredientType.description = translationIndex === description ? defaultDescription : description;
    });

    // remove vegetarian, if vegan is set
    if(vegan && (vegetarianIndex != -1)) {
        ingredientTypes.splice(vegetarianIndex, 1);
    }

    // render
    return (
        <>
            <div className="accordion accordion-flush" id="ingredientTypeList">
                {ingredientTypes.map((ingredientType, index) =>
                    <IngredientType
                        key={'ingredientType-' + index}
                        id={id}
                        ingredientType={ingredientType}
                    />
                )}
            </div>
        </>
    );
}