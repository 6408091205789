import React from "react";
import SubmitButton from "../button";
import {useTranslation} from "next-i18next";
//import RecaptchaClient from "../../lib/client/recaptcha";

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
export default function Button({ id, background = 'light' }) {
    // init
    const { t } = useTranslation();

    /**
     *
     * @param event
     */
    function callback(event) {
        return new Promise(async resolve => {
            const form = event.target.closest('form');
            setTimeout(
                resolve,
                10000
            );

            event.preventDefault();
            form.submit();
            /*if(form.checkValidity()) {
                setTimeout(
                    resolve,
                    10000
                );

                event.preventDefault();
                form.submit();
            } else {
                form.reportValidity();
                resolve();
            }*/
        });
    }

    // render
    return (
        <>
            <SubmitButton
                id={id}
                outline={false}
                text={t('search')}
                color={'light' === background ? 'primary' : 'light'}
                callback={callback}
                hideTextOnMobile={true}
                submitOnlyOnce={false}
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor"
                     className="buttonIcon bi bi-search align-text-bottom" viewBox="0 0 16 16">
                    <path
                        d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                </svg>
            </SubmitButton>
        </>
    )
}