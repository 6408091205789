/**
 *
 * @param capabilities
 */
export function calculateCameraScore(capabilities) {
    let score = 0

    score += capabilities?.height?.max ?? 0
    score += capabilities?.width?.max ?? 0
    score += capabilities?.frameRate?.max ?? 0
    score += capabilities?.iso?.max ?? 0
    score *= (!!capabilities.torch ? 2 : 1)

    return score
}

/**
 *
 * @param videoDevices
 */
export function sortCamerasByNearestPrimary(videoDevices) {
    videoDevices.sort((a, b) => {
        if(a.score === b.score) return 0
        return a.score > b.score ? -1 : 1
    })
}

// module.exports = {
//     calculateCameraScore,
//     sortCamerasByNearestPrimary
// }