import Header from "./modal/header";
import Body from "./modal/body";
import Footer from "./modal/footer";

/**
 *
 * @param canvasVisible
 * @param canvasWidth
 * @param canvasHeight
 * @param startScanHandler
 * @param stopScanHandler
 * @param product
 * @param loading
 * @param notFound
 * @param error
 * @param setVideoDeviceId
 * @param torchAvailable
 * @returns {JSX.Element}
 * @constructor
 */
export default function Modal({ canvasVisible, canvasWidth, canvasHeight, startScanHandler, stopScanHandler, product, loading, notFound, error, setVideoDeviceId, torchAvailable })
{
    // render
    return(
        <>
            <div id="baracodeScanModal" className={'modal fade p-0'} tabIndex="-1" data-bs-backdrop="static" data-bs-keyboard={false} aria-labelledby="barcodeScanModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-fullscreen-md-down">
                    <div className="modal-content">
                        <Header
                            stopScanHandler={stopScanHandler}
                        />
                        <Body
                            canvasVisible={canvasVisible}
                            canvasWidth={canvasWidth}
                            canvasHeight={canvasHeight}
                            product={product}
                            loading={loading}
                            notFound={notFound}
                            error={error}
                        />
                        <Footer
                            canvasVisible={canvasVisible}
                            startScanHandler={startScanHandler}
                            stopScanHandler={stopScanHandler}
                            setVideoDeviceId={setVideoDeviceId}
                            torchAvailable={torchAvailable}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}