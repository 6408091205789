import styles from './search.module.css';
import {useState} from "react";
import {useRouter} from "next/router";
//import RecaptchaClient from "../../lib/client/recaptcha";

var abortController = null;

/**
 *
 * @param type
 * @param placeholder
 * @param defaultValue
 * @param apiURL
 * @returns {JSX.Element}
 * @constructor
 */
export default function Input({ name, type, multiple, placeholder, defaultValue, required, loadOnChoose }) {
    // init
    const router = useRouter();
    const [listItems, setListItems] = useState([]);
    const [productName, setProductName] = useState(defaultValue);
    const [autocomplete, setAutocomplete] = useState(true);

    /**
     *
     * @param event
     * @returns {Promise<void>}
     */
    async function search(event) {
        setProductName(event.target.value);

        if(0 === event.target.value.length) {
            setListItems([]);
            return;
        }

        if(!autocomplete) {
            setAutocomplete(true);
            return;
        }

        /*// get the recaptcha-token
        const recaptchaClient = new RecaptchaClient(process.env.recaptchaKey);
        const token = await recaptchaClient.execute('/search/' + type);*/

        // if we had a previous request, cancel it
        if(abortController) abortController.abort();
        abortController = new AbortController();

        // make the search-request
        fetch(
            '/api/search?locale=' + router.locale + '&type=' + encodeURIComponent(type) + '&query=' + encodeURIComponent(event.target.value)/* + '&token=' + encodeURIComponent(token)*/,
            {
                signal: abortController.signal
            }
        )
            .then(response => {
                if (!response.ok) {
                    response.text().then(text => {
                        throw new Error(text);
                    });
                }
                return response.json();
            })
            .then(data => {
                if('ok' === data.header.status && data.body.search) {
                    // create and set the list-items
                    let items = [];
                    data.body.search.map((item) => {
                        // check which part corresponds to the searchterm, so we can highlight it
                        let regEx = new RegExp("(.*)(" + event.target.value + ")(.*)", "i");
                        let matches = item.title.match(regEx);

                        // add the item
                        items.push({
                            'id':       item.entityId,
                            'type':     item.type,
                            'title':    item.title,
                            'matches':  (matches && matches.length > 0) ? matches : null
                        })
                    });
                    setListItems(items);
                } else {
                    setListItems([]);
                }

                //setLoading(false);
            })
            .catch(reason => console.error(reason));
    }

    /**
     *
     * @param event
     */
    function blur(event) {
        setListItems([]);
    }

    /**
     *
     * @param event
     */
    function choose(event) {
        const element = event.target.getAttribute('data-eqno-title') ? event.target : event.target.parentNode;
        setAutocomplete(false);
        setProductName(element.getAttribute('data-eqno-title'));
        setListItems([]);

        // load the new page, if load-on-chosse is set to true
        if(loadOnChoose) {
            router.push('/' + element.getAttribute('data-eqno-type') + '/' + element.getAttribute('data-eqno-id'));
        }
    }

    // render
    return (
        <>
            <input
                onChange={search}
                onBlur={blur}
                className={'form-control'}
                name={name ?? (type + (multiple ? 's[]' : ''))}
                type={'search'}
                placeholder={placeholder}
                value={productName}
                aria-label={placeholder}
                required={required}
            />
            <ul className={'searchResult ' + styles.searchResult + ' pe-4'} >
                {listItems.map((listItem, index) =>
                    <li
                        onMouseDown={choose}
                        key={index}
                        className={'p-2'}
                        data-eqno-id={listItem.id}
                        data-eqno-title={listItem.title}
                        data-eqno-type={listItem.type}
                    >
                        <span onClick={choose}>{listItem.matches ? (listItem.matches[1] ?? '') : listItem.title}</span>
                        <strong onClick={choose}>{listItem.matches ? (listItem.matches[2] ?? '') : ''}</strong>
                        <span onClick={choose}>{listItem.matches ? (listItem.matches[3] ?? '') : ''}</span>
                    </li>
                )}
            </ul>
        </>
    )
}