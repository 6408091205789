import {useTranslation} from "next-i18next";
import {useRouter} from "next/router";
import {useEffect, useRef} from "react";

/**
 *
 * @param visible
 * @param stopScanHandler
 * @returns {JSX.Element}
 * @constructor
 */
export default function Header({ stopScanHandler })
{
    // stop scan callback
    function stopScan(e) {
        e.preventDefault();
        stopScanHandler();
    }

    // init
    const { t } = useTranslation();
    const router = useRouter();
    const buttonReference = useRef();

    // to make sure the modal closes if the user navigates to a different page
    useEffect(
        function () {
            const routeChangeStart = () => {
                buttonReference.current.click();
            };

            router.events.on('routeChangeStart', routeChangeStart);

            return () => {
                router.events.off('routeChangeStart', routeChangeStart);
            }
        },
        [router.events]
    )

    // render
    return(
        <>
            <div className="modal-header">
                <h5 id="barcodeScanModalLabel" className="modal-title">{t('scan.barcode')}</h5>
                <button ref={buttonReference} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={stopScan} />
            </div>
        </>
    );
}