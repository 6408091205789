import {useTranslation} from "next-i18next";
import React from "react";
import Input from "./input";
import {useRouter} from "next/router";
import Button from "./button";
import Link from "next/link";

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
export default function Form({ type, background = 'light' }) {
    const router = useRouter();
    const { t } = useTranslation();

    return (
        <>
            <form action={'/'+ router.locale + '/product/search'} method="GET" autoComplete="off">
                <div className="row justify-content-center">
                    <div className="position-relative col-9 col-md-8"> {/*col-sm-9 col-md-6 col-lg-5 col-xl-4*/}
                        <Input
                            name={'query'}
                            type={type}
                            defaultValue={''}
                            placeholder={t('searchterm')}
                            required={true}
                            multiple={false}
                            loadOnChoose={true}
                        />
                    </div>
                    <div className={'w-auto'}>
                        <Button id={'search-submit-btn'} background={background} />
                    </div>
                </div>
                <div className="text-center mt-4">
                    <Link href={'/product/search'} className={'light' === background ? '' : 'text-light'}>
                        {t('view.all.products', { ns: 'glossary' })}
                    </Link>
                </div>
            </form>
        </>
    )
}