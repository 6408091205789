import React, {useEffect} from "react";
import {sortCamerasByNearestPrimary, calculateCameraScore} from "../../lib/camera"
import useStateWithLocalStorage, {LOCAL_STORAGE_STATUS} from "../../lib/hooks/useStateWithLocalStorage";
import useStateWithCookie, {COOKIE_STATUS} from "../../lib/hooks/useStateWithCookie";

async function enhanceDevice(videoDevice) {
    const stream= await navigator.mediaDevices.getUserMedia({audio: false, video:{deviceId:{exact: videoDevice.id}}})
    const videoTracks = stream.getVideoTracks()
    const capabilities = videoTracks[0].getCapabilities ? videoTracks[0].getCapabilities() : {}
    videoDevice.score = calculateCameraScore(capabilities)
    //videoDevice.debug += ' (Score:' + calculateCameraScore(capabilities) + ' ' + capabilities?.width?.max + 'x' + capabilities?.height?.max + ' ISO:' + (capabilities?.iso?.max ?? 'n/a') + ' torch:' + (!!capabilities.torch ? 'yes ' : 'no ') +  + capabilities?.frameRate?.max + 'fps)'
    stream.getTracks().forEach(track => {
        track.stop();
        stream.removeTrack(track);
    });
}

async function calculateDeviceScores(videoDevices) {
    for (const videoDevice of videoDevices) {
        await enhanceDevice(videoDevice)
    }
}

/***
 *
 * @param setVideoDeviceId
 * @returns {JSX.Element}
 * @constructor
 */
export default function Devices( { setVideoDeviceId } ) {
    // init
    const [videoDevices, setVideoDevices] = useStateWithLocalStorage('videoDevices', JSON.stringify([]));
    const [lastVideoDeviceId, setLastVideoDeviceId] = useStateWithCookie('videodevice', '');

    useEffect(
        () => {
            async function fetchVideoDevices() {
                if(lastVideoDeviceId === COOKIE_STATUS.NOT_INITIALIZED || videoDevices === LOCAL_STORAGE_STATUS.NOT_INITIALIZED) return

                try {
                    if('' === lastVideoDeviceId) {
                        const constraints = {
                            audio: false,
                            video: true
                        };
                        const stream= await navigator.mediaDevices.getUserMedia(constraints);

                        // Stop all accessed tracks to free the camera for the barcode scanner
                        stream.getTracks().forEach(track => track.stop())
                    }

                    let videoDevicesFound = [];
                    let cached = true
                    const cachedVideoDevices = JSON.parse(videoDevices)
                    const devices = await navigator.mediaDevices.enumerateDevices();
                    devices.forEach((device) => {
                        if('videoinput' === device.kind) {
                            videoDevicesFound.push(
                                {
                                    'name': device.label,
                                    'id': device.deviceId
                                }
                            )

                            // cached?
                            if(cached && !cachedVideoDevices.find(cachedVideoDevice => cachedVideoDevice.id === device.deviceId)) {
                                cached = false;
                            }
                        }
                    });

                    if(cached && (videoDevicesFound.length === cachedVideoDevices.length)) videoDevicesFound = JSON.parse(videoDevices)
                    else {
                        await calculateDeviceScores(videoDevicesFound)
                        sortCamerasByNearestPrimary(videoDevicesFound);
                        setVideoDevices(JSON.stringify(videoDevicesFound));
                    }
                    const videoDeviceId = '' === lastVideoDeviceId ? videoDevicesFound[0].id : lastVideoDeviceId
                    setVideoDeviceId(videoDeviceId)
                    setLastVideoDeviceId(videoDeviceId)
                } catch (exception) {
                    console.error(`${exception.name}: ${exception.message}`);
                }
            }
            if (navigator.mediaDevices?.getUserMedia && navigator.mediaDevices?.enumerateDevices) {
                fetchVideoDevices();
            } else setVideoDeviceId(null)
        },
        [lastVideoDeviceId, videoDevices]
    )

    // on change handler
    async function changeVideoDevice(event) {
        const videoDeviceId = event.target.options[event.target.selectedIndex].value
        setVideoDeviceId(videoDeviceId)
        setLastVideoDeviceId(videoDeviceId)
    }

    const videoDeviceOptions = videoDevices === LOCAL_STORAGE_STATUS.NOT_INITIALIZED ? [] : JSON.parse(videoDevices)

    // render
    return (
        <>
            {videoDeviceOptions.length ?
                <div className={'text-center'}>
                    <select value={lastVideoDeviceId} onChange={changeVideoDevice} className={'form-select d-inline-block w-auto'}>
                        {
                            (videoDeviceOptions.map((videoDevice, index) =>
                                <option key={'video-device-' + index} value={videoDevice.id}>{videoDevice.name}</option>
                            ))
                        }
                    </select>
                </div> : ''
            }
        </>
    )
}