type Data = {
    type : string | null
    value : number | null
    size : string
};

/**
 *
 * @param type
 * @param value
 * @param size
 * @constructor
 */
export default function TrafficLight ({ type, value, size} : Data) {
    // init css
    let style = "badge rounded-circle fw-bolder text-center mb-0 p-0";

    // set the size
    style += (size === "small" ? " traffic_light_small fs-6" :
        (size === "tiny" ? " traffic_light_tiny fs-7" : " traffic_light_big fs-5"));

    const displayValue = value ?? 'n/a';

    // set the text- and background-color
    if('green' === type) {
        style += " bg-success";
        return <p className={style}>{displayValue}</p>
    } else if ('red' === type) {
        style += " bg-danger";
        return <p className={style}>{displayValue}</p>
    } else if ('yellow' === type) {
        style += " bg-warning";
        return <p className={style}>{displayValue}</p>
    } else {
        style += " bg-light border border-dark text-dark";
        return <p className={style}>{displayValue}</p>
    }
}