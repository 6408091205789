import SingleScore from "../score";
import React from "react";
import {useTranslation} from "next-i18next";
import Link from "next/link";

/**
 *
 * @returns {JSX.Element}
 */
export default function Score({ link, target, score, nutrientScore, ingredientScore })
{
    // init
    const { t } = useTranslation();
    //const adjustedScore = (!nutrientScore && !ingredientScore) ? null : score;
    const adjustedScore = score;

    // render
    return(
        <>
            {
                '' === link ?
                <div className={"traffic_light_big_surrounding text-center"}>
                    <SingleScore size={"big"} value={adjustedScore} />
                    <p className="small fw-bold mt-1">{t('score')}</p>
                </div>
                :
                <Link href={link} target={target} className={"traffic_light_big_surrounding text-decoration-none text-center"}>
                    <SingleScore size={"big"} value={adjustedScore} />
                    <p className="small fw-bold mt-1">{t('score')}</p>
                </Link>
            }
            <div>
                <p className="text-center fw-bolder p-1 pt-3">=</p>
            </div>
            <Link href={link + "#nutrients"} target={target} className={"traffic_light_big_surrounding text-decoration-none text-center"}>
                <SingleScore size={"big"} value={nutrientScore} />
                <p className="small mt-1">{t('nutrients')}</p>
            </Link>
            <div>
                <p className="text-center p-1 pt-3">+</p>
            </div>
            <Link href={link + "#ingredients"} target={target} className={"traffic_light_big_surrounding text-decoration-none text-center"}>
                <SingleScore size={"big"} value={ingredientScore} />
                <p className="small mt-1">{t('ingredients')}</p>
            </Link>
        </>
    );
}