import TrafficLight from "./trafficLight";

type Data = {
    value : number | null
    size : string
};

/**
 *
 * @param value
 * @param size
 * @returns {JSX.Element}
 * @constructor
 */
export default function Score ({ value, size } : Data) {
    // set the text- and background-color
    if(null == value) {
        return <TrafficLight type={null} value={null} size={size} />
    } else if (value < 75) {
        return <TrafficLight type={'red'} value={value} size={size} />
    } else if (value < 90) {
        return <TrafficLight type={'yellow'} value={value} size={size} />
    } else {
        return <TrafficLight type={'green'} value={value} size={size} />
    }
}