import Link from 'next/link';
import Score from "./score";
import IngredientTypeList from "./ingredientTypeList";
import {IngredientTypeData} from "./ingredientType";

export type ProductData = {
    id : string
    img : string
    title : string
    link : string
    score : number
    ingredientTypes : IngredientTypeData[]
};

type Data = {
    id : string
    product : ProductData
};

/**
 *
 * @param id
 * @param product
 * @constructor
 */
export default function Product ({ id, product } : Data) {
    // render
    return (
        <>
            <div className="product col-12 col-sm-12 col-md-6 col-lg-4 p-1 m-md-0 mb-md-4 mb-3">
                <div className="container p-0 m-0">
                    <div className="row">
                        <Link href={product.link} className="text-decoration-none text-start text-md-center col-2 col-sm-1 col-md-12 mb-md-3">
                            {/*<Image src={img} alt={title} width={512} height={512} />*/}
                            {/*<Placeholder height={96} />*/}
                            <Score value={product.score} size={"big"} />
                        </Link>
                        <div className="col-10 col-sm-11 col-md-12 ps-xs-2 ps-sm-5 ps-md-3 pe-md-3">
                            <Link href={product.link} className={'text-decoration-none'}>
                                <h2 className="h5 text-start text-md-center mb-2 text-truncate">{product.title}</h2>
                            </Link>
                            <div className="row justify-content-md-center m-0">
                                {/*<Score value={score} size={"small"} />*/}
                                <IngredientTypeList
                                    id={id + '-' + product.id}
                                    singular={false}
                                    ingredientTypes={product.ingredientTypes}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}