import ProductHeader from "../product/header";
import Link from "next/link";
import {useTranslation} from "next-i18next";

/**
 *
 * @param modal
 * @param visible
 * @param title
 * @param link
 * @param score
 * @param nutrientScore
 * @param ingredientScore
 * @param ingredientTypes
 * @returns {JSX.Element}
 * @constructor
 */
export default function Product({ modal, visible, title, link, score, nutrientScore, ingredientScore, ingredientTypes })
{
    // init
    const { t } = useTranslation();

    // render
    return(
        <>
            <div className={"mb-5 " + (visible ? 'd-block' : 'd-none')}>
                {/* Product */}
                <ProductHeader
                    embed={true}
                    title={title}
                    link={link}
                    target={'_self'}
                    score={score}
                    nutrientScore={nutrientScore}
                    ingredientScore={ingredientScore}
                    ingredientTypes={ingredientTypes}
                />

                {/* CTA */}
                {
                    modal
                        ?
                        <div className={'text-center'} data-bs-toggle="modal" data-bs-target="#baracodeScanModal">
                            <Link href={link} className="btn btn-primary mt-4" role="button">
                                {t('more.details')}
                            </Link>
                        </div>
                        :
                        <div className={'text-center'}>
                            <Link href={link} className="btn btn-primary mt-4" role="button">
                                {t('more.details')}
                            </Link>
                        </div>
                }
            </div>
        </>
    );
}