import {useTranslation} from "next-i18next";
import Canvas from "../canvas";
import Product from "../product";
import PageSpinner from "../../spinner/page";
import Error from "../../alert/error";
import React from "react";
import NotFound from "./notFound";

/**
 *
 * @param canvasVisible
 * @param canvasWidth
 * @param canvasHeight
 * @param product
 * @param loading
 * @param notFound
 * @param error
 * @returns {JSX.Element}
 * @constructor
 */
export default function Body({ canvasVisible, canvasWidth, canvasHeight, product, loading, notFound, error })
{
    // init
    const { t } = useTranslation();

    // render
    return(
        <>
            <div className={'p-3 pb-0'}>
                <p className={'text-center' + (canvasVisible ? ' d-block' : ' d-none')}>{t('barcode.scanner.body', {ns: 'glossary'})}</p>
                <Canvas visible={canvasVisible} width={canvasWidth} height={canvasHeight} />
                {loading ? <PageSpinner /> : ''}
                {notFound ? <NotFound /> : ''}
                {error ? <Error message={error} allowHTML={true} /> : ''}
                <Product
                    modal={false}
                    visible={!!product}
                    title={product ? product.title : ''}
                    link={product ? product.link : '/'}
                    score={product ? product.score : ''}
                    nutrientScore={product ? product.nutrientScore : ''}
                    ingredientScore={product ? product.ingredientScore : ''}
                    ingredientTypes={product ? product.ingredientTypes : ''}
                />
            </div>
        </>
    );
}