import React from "react";
import {useTranslation} from "next-i18next";

/**
 *
 * @param message
 * @returns {JSX.Element}
 * @constructor
 */
export default function Info ({ message }) {
    const { t } = useTranslation();

    return (
        <>
            <div className="d-flex alert alert-info alert-dismissible fade show align-items-center mt-3" role="alert">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                     className="bi bi-info-square-fill flex-shrink-0 me-3" viewBox="0 0 16 16">
                    <path
                        d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.93 4.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
                </svg>
                <div className={'d-inline'}><strong>{t('information')}:</strong> {message}</div>
                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" />
            </div>
        </>
    );
}