import {useTranslation} from "next-i18next";
import Info from "../../alert/info";
import Form from "../../search/form";
import React from "react";
import Link from "next/link";

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
export default function NotFound()
{
    // init
    const { t } = useTranslation();

    // callback to close the modal
    function closeModal(event) {
        // only close the modal, if it is a button, list-Item or a link
        if(
            'A' === event.target.nodeName ||
            'LI' === event.target.nodeName ||
            'LI' === event.target.parentElement.nodeName // span or strong of the li
        ) {
            // hide the modal
            bootstrap.Modal.getInstance(
                document.getElementById('baracodeScanModal')
            ).hide();
        }
    }

    // render
    return(
        <>
            {/* Info-Message */}
            <Info message={t('product.not.found', {ns: 'glossary'})} />

            {/* Search */}
            <h2 className="row justify-content-center mt-5 pt-3 mb-4">{t('search.product')}</h2>
            <div onClick={closeModal}><Form type={'product'} /></div>

            {/* Add product */}
            <div className="text-center mt-5 mb-5 pt-3">
                <h2 className="row justify-content-center mb-4">{t('add.product')}</h2>
                <Link href={'/product/add'} onClick={closeModal} className={'btn btn-primary'} role={'button'}>
                    {t('add.product')}
                </Link>
            </div>
        </>
    );
}