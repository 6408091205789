import Product, {ProductData} from "./product";

type Data = {
    id : string
    products : ProductData[]
}

/**
 *
 * @param id
 * @param products
 * @constructor
 */
export default function ProductList ({ id, products } : Data) {
    // render
    return (
        <>
            <div className="row justify-content-md-start text-md-center m-md-auto">
                {products.map((product, index) =>
                    <Product
                        key={index + '-' + product.id}
                        id={id}
                        product={product}
                    />
                )}
            </div>
        </>
    )
}