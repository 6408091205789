import { Dispatch, SetStateAction, useEffect, useState } from "react";
import Cookie from "../client/cookies";

/**
 *
 */
type ReturnType = [string, Dispatch<SetStateAction<string>>];

/**
 *
 */
export enum COOKIE_STATUS {
    NOT_INITIALIZED = 'COOKIE_NOT_INITIALIZED'
}

/**
 *
 * @param cookieKey
 * @param defaultValue
 */
export default function useStateWithCookie(
    cookieKey: string, // Needs to be unique
    defaultValue: string
): ReturnType {
    const [value, setValue] = useState(COOKIE_STATUS.NOT_INITIALIZED.toString());

    // on init
    useEffect(() => {
        let cookie = new Cookie();
        setValue(cookie.getCookie(cookieKey) ?? defaultValue);
    }, [cookieKey, defaultValue]);

    // on change
    useEffect(() => {
        if(COOKIE_STATUS.NOT_INITIALIZED.toString() !== value) {
            let cookie = new Cookie();
            cookie.setCookie(cookieKey, value);
        }
    }, [value, cookieKey, defaultValue]);

    return [value, setValue];
}