/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
export default function Button () {
    return (
        <>
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden={true} />
        </>
    );
}