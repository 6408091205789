import {useTranslation} from "next-i18next";

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
export default function Header()
{
    // init
    const { t } = useTranslation();

    // render
    return(
        <>
            <h1 className={'text-center fw-bold ms-3 me-3 ms-md-5 me-md-5 mt-4'}>{t('scan.barcode')}</h1>
        </>
    );
}