import {useTranslation} from "next-i18next";
import Info from "../../alert/info";
import Form from "../../search/form";
import React from "react";
import Link from "next/link";

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
export default function NotFound()
{
    // init
    const { t } = useTranslation();

    // render
    return(
        <>
            {/* Info-Message */}
            <Info message={t('product.not.found', {ns: 'glossary'})} />

            {/* Search */}
            <h2 className="row justify-content-center mt-5 pt-3 mb-4">{t('search.product')}</h2>
            <div><Form type={'product'} /></div>

            {/* Add product */}
            <div className="text-center mt-5 mb-5 pt-3 pb-4">
                <h2 className="row justify-content-center mb-4">{t('add.product')}</h2>
                <Link href={'/product/add'} className={'btn btn-primary'} role={'button'}>
                    {t('add.product')}
                </Link>
            </div>
        </>
    );
}