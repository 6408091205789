import React from "react";
import Score from "./score";
import Link from "next/link";
import IngredientTypeList from "../ingredientTypeList";

/**
 *
 * @param embed
 * @param title
 * @param link
 * @param target
 * @param score
 * @param nutrientScore
 * @param ingredientScore
 * @param ingredientTypes
 * @returns {JSX.Element}
 * @constructor
 */
export default function Header({ embed, title, link, target, score, nutrientScore, ingredientScore, ingredientTypes })
{
    // render
    return(
        <>
            {/* Title */}
            {
                '' === link ?
                    embed ? <h2 className="h1 mb-4 pb-2 mt-3 text-center">{title}</h2> : <h1 className="mb-4 pb-2 mt-3 text-center">{title}</h1>
                    :
                    <Link href={link} target={target} className="text-decoration-none">
                        {embed ? <h2 className="h1 mb-4 pb-2 mt-3 text-center">{title}</h2> : <h1 className="mb-4 pb-2 mt-3 text-center">{title}</h1>}
                    </Link>
            }

            {/* Score */}
            <div className="w-100 d-inline-flex justify-content-center mb-3">
                <Score link={link} target={target} score={score} nutrientScore={nutrientScore} ingredientScore={ingredientScore} />
            </div>

            {/* Ingredient-Types */}
            {ingredientTypes && ingredientTypes.length ?
                <div className="d-flex justify-content-center mb-3">
                    <IngredientTypeList
                        id={'product-embed'}
                        singular={false}
                        ingredientTypes={ingredientTypes}
                    />
                </div>
                : ''
            }
        </>
    );
}