/**
 *
 * @param visible
 * @param width
 * @param height
 * @returns {JSX.Element}
 * @constructor
 */
export default function Canvas({ visible, width, height })
{
    // render
    return(
        <>
            <div id="interactive" className={'text-center viewport' + (visible ? ' d-block' : ' d-none')}>
                <video
                    className="border border-3 videoCamera"
                    autoPlay={true}
                    preload="auto"
                    src=""
                    muted={true}
                    playsInline={true}
                    width={width}
                    height={height}
                />
                <canvas className="drawingBuffer d-none" />
                <br />
            </div>
        </>
    );
}