import React, {useEffect, useState} from "react";
import {useTranslation} from "next-i18next";
import Quagga from "@ericblade/quagga2";

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
export default function Light({ available })
{
    // init
    const [active, setActive] = useState(false)

    useEffect(() => {
        return async function cleanUp () {
            if(available && active) await Quagga?.CameraAccess?.disableTorch()
        }
    }, [])

    /**
     *
     * @param event
     */
    async function enableTorch(event) {
        event.preventDefault()
        if(available && !active) {
            await Quagga.CameraAccess.enableTorch()
            setActive(true)
        }
    }

    /**
     *
     * @param event
     */
    async function disableTorch(event) {
        event.preventDefault()
        if(available && active) {
            await Quagga.CameraAccess.disableTorch()
            setActive(false)
        }
    }

    // init
    const { t } = useTranslation();

    // render
    return(
        <>
            <button disabled={!available} className={"form-control btn btn-outline-warning w-auto m-1"} onClick={active ? disableTorch : enableTorch}>
                <span className={'me-2'}>{t('light')}</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     className="buttonIcon bi bi-lightning align-text-bottom" viewBox="0 0 16 16">
                    <path d="M5.52.359A.5.5 0 0 1 6 0h4a.5.5 0 0 1 .474.658L8.694 6H12.5a.5.5 0 0 1 .395.807l-7 9a.5.5 0 0 1-.873-.454L6.823 9.5H3.5a.5.5 0 0 1-.48-.641l2.5-8.5zM6.374 1 4.168 8.5H7.5a.5.5 0 0 1 .478.647L6.78 13.04 11.478 7H8a.5.5 0 0 1-.474-.658L9.306 1H6.374z"/>
                </svg>
            </button>
        </>
    );
}