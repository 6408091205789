import Header from "./page/header";
import Body from "./page/body";
import Footer from "./page/footer";
import {useEffect} from "react";

/**
 *
 * @param canvasVisible
 * @param canvasWidth
 * @param canvasHeight
 * @param startScanHandler
 * @param stopScanHandler
 * @param product
 * @param loading
 * @param notFound
 * @param error
 * @param lastScanned
 * @param setVideoDeviceId
 * @param torchAvailable
 * @returns {JSX.Element}
 * @constructor
 */
export default function Page({ canvasVisible, canvasWidth, canvasHeight, startScanHandler, stopScanHandler, product, loading, notFound, error, lastScanned, setVideoDeviceId, torchAvailable })
{
    // init
    useEffect(
        () => {
            startScanHandler();

            return function cleanUp () {
                stopScanHandler();
            }
        },
        []
    );

    // render
    return(
        <>
            <Header />
            <Body
                canvasVisible={canvasVisible}
                canvasWidth={canvasWidth}
                canvasHeight={canvasHeight}
                product={product}
                loading={loading}
                notFound={notFound}
                error={error}
            />
            <Footer
                startScanHandler={startScanHandler}
                stopScanHandler={stopScanHandler}
                canvasVisible={canvasVisible}
                lastScanned={lastScanned}
                torchAvailable={torchAvailable}
                setVideoDeviceId={setVideoDeviceId}
            />
        </>
    );
}