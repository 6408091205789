import {useEffect} from "react";

/**
 *
 */
type Tooltip = {
    title : string
    content : string
    placement : string
};

/**
 *
 */
type Data = {
    id : string
    type : string,
    value : string,
    outline ?: boolean
    tooltip ?: Tooltip
};

/**
 *
 * @param id
 * @param type
 * @param value
 * @param outline
 * @param tooltip
 * @constructor
 */
export default function Badge ({ id, type, value, outline = true, tooltip }:Data) {
    // init css
    let style = "text-decoration-none p-1 badge me-1 mb-1 ms-md-1 w-auto";
    const badgeStyle = outline ? ' badge-outline-' : ' bg-';

    // init the tooltip
    useEffect(
        () => {
            // receive the element, which got a tooltip
            let element = document.getElementById(id);

            // init it if title is non-empty
            if(element && ('' !== element.getAttribute('title'))) {
                // @ts-ignore
                //bootstrap.Popover.getOrCreateInstance(element);
                bootstrap.Tooltip.getOrCreateInstance(element);
            }
        },
        [id]
    );

    // set the text- and background-color
    if ("red" === type) {
        style += badgeStyle + "danger";
    } else if ("yellow" === type) {
        style += badgeStyle + "warning";
    } else {
        style += badgeStyle + "success";
    }

    // render
    return (
        <span
            id={id}
            tabIndex={0}
            className={style}
            role="button"
            data-bs-toggle="tooltip"//"popover"
            data-bs-trigger="focus"
            data-bs-placement={tooltip ? tooltip.placement : ''}
            title={tooltip ? tooltip.title : ''}
            data-bs-content={tooltip ? tooltip.content : ''}
            data-bs-container={'body'}
            data-bs-html={true}
        >
            {value}
        </span>
    );
}