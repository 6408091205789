import Start from "../button/start";
import Stop from "../button/stop";
import ProductList from "../../productList";
import React from "react";
import {useTranslation} from "next-i18next";
import Devices from "../devices";
import Light from "../button/light";

/**
 *
 * @param startScanHandler
 * @param stopScanHandler
 * @param canvasVisible
 * @param lastScanned
 * @param setVideoDeviceId
 * @param torchAvailable
 * @returns {JSX.Element}
 * @constructor
 */
export default function Footer({ startScanHandler, stopScanHandler, canvasVisible, lastScanned, setVideoDeviceId, torchAvailable })
{
    // init
    const { t } = useTranslation();

    // render
    return(
        <>
            <div className="mt-4 mb-5">
                {canvasVisible ?
                    <>
                    <Devices setVideoDeviceId={setVideoDeviceId} />
                    <div className={'row justify-content-center m-0 mt-4'}>
                        <Light available={torchAvailable} />
                        <Stop stopScanHandler={stopScanHandler} />
                    </div>
                    </>
                    : <div className={'row justify-content-center m-0 mt-4'}><Start startScanHandler={startScanHandler} toggleModal={false} /></div>
                }
                {
                    lastScanned.length
                        ?
                        <div className={'mt-3 p-3 p-sm-5 pt-5'}>
                            <h2 className={'mb-3'}>{t('last.scanned.products', { ns: 'glossary' })}</h2>
                            <ProductList id={'last-scanned-products'} products={lastScanned} />
                        </div>
                        :
                        ''
                }
            </div>
        </>
    );
}