import Modal from "./modal";
import React, {useEffect, useState} from "react";
import Start from "./button/start";
import Quagga from '@ericblade/quagga2'
import { useRouter } from 'next/router';
//import Mobile from "../../lib/client/mobile";
//import RecaptchaClient from "../../lib/client/recaptcha";
import Page from "./page";
import useStateWithLocalStorage, {LOCAL_STORAGE_STATUS} from "../../lib/hooks/useStateWithLocalStorage";
import {useTranslation} from "next-i18next";
//import Debug from "./debug";

const maxVideoDimensions = 400

/**
 *
 * @type {{locate: boolean, multiple: boolean, inputStream: {type: string, constraints: {facingMode: string, width: number, height: number}}, decoder: {readers: string[]}, locator: {halfSample: boolean, patchSize: string}, numOfWorkers: number, frequency: number}}
 */
let quaggaConfig = {
    //"debug": true,
    "inputStream": {
        "type": "LiveStream",
        "constraints": {
            "width": {"ideal": maxVideoDimensions},
            "height": {"ideal": maxVideoDimensions},
            "facingMode": {"ideal": "environment"}
        },
        /*"area": { // defines rectangle of the detection/localization area
            "top": "10%",    // top offset
            "right": "10%",  // right offset
            "left": "10%",   // left offset
            "bottom": "10%"  // bottom offset
        },*/
    },
    "locator": {
        "patchSize": "medium",
        "halfSample": true,
        /*"debug": {
            "showCanvas": true,
            "showPatches": true,
            "showFoundPatches": true,
            "showSkeleton": true,
            "showLabels": true,
            "showPatchLabels": true,
            "showRemainingPatchLabels": true,
            "boxFromPatches": {
                "showTransformed": true,
                "showTransformedBox": true,
                "showBB": true
            }
        }*/
    },
    "numOfWorkers": 0,
    "frequency": 10,
    "decoder": {
        "readers": [
            "ean_reader",
            "ean_8_reader"
        ],
        /*"debug": {
            "drawBoundingBox": true,
            "showFrequency": true,
            "drawScanline": true,
            "showPattern": true
        }*/
    },
    "locate": false,
    "multiple": false
};

/**
 *
 * @param modal
 * @param background
 * @returns {JSX.Element}
 * @constructor
 */
export default function Scanner({ modal, background = 'light' })
{
    // init
    const maxLastScannedProducts = 6;

    // hooks
    const router = useRouter();
    const { t } = useTranslation();

    // members
    const [scannerShouldRun, setScannerShouldRun] = useState(false);
    const [canvasVisibility, setCanvasVisibility] = useState(false);
    const [canvasWidth, setCanvasWidth] = useState(0);
    const [canvasHeight, setCanvasHeight] = useState(0);
    const [code, setCode] = useState('');
    const [product, setProduct] = useState(null);
    const [loading, setLoading] = useState(false);
    const [notFound, setNotFound] = useState(false);
    const [error, setError] = useState(null);
    const [lastScanned, setLastScanned] = useStateWithLocalStorage(
        'last-scanned',
        JSON.stringify([])
    );
    const [videoDeviceId, setVideoDeviceId] = useState('')
    //const [debugMessage, setDebugMessage] = useState('')
    const [torchAvailable, setTorchAvailable] = useState(false)

    // calculate video dimensions and determine the device id
    useEffect(
        () => {
            // if the screen width is smaller than the height, swap the config
            /*if (screen.availWidth < screen.availHeight) {
                const oldWidth = config.inputStream.constraints.width;
                config.inputStream.constraints.width = config.inputStream.constraints.height;
                config.inputStream.constraints.height = oldWidth;
            }*/

            // Make sure the width doesn't exceed the display-width
            const margin = 40;
            let videoWidth = maxVideoDimensions
            if((screen.availWidth - videoWidth) < margin) {
                videoWidth = screen.availWidth - margin;
                //setCanvasWidth(config.inputStream.constraints.width);// * 0.5625; // 9/16
            }

            // Make sure the height doesn't exceed the display-height
            let videoHeight = maxVideoDimensions
            if((screen.availHeight - videoHeight) < margin) {
                // adjust the height accordingly
                videoHeight = screen.availHeight - margin;
            }

            // update dimensions
            const dimension = Math.min(videoWidth, videoHeight)
            setCanvasWidth(dimension)
            setCanvasHeight(dimension)

            // on mobile and portrait mode switch width and height
            // const mobile = new Mobile();
            // if (mobile.isMobile() && window.matchMedia("(orientation: portrait)").matches) {
            //     const oldWidth = config.inputStream.constraints.width;
            //     config.inputStream.constraints.width = config.inputStream.constraints.height;
            //     config.inputStream.constraints.height = oldWidth;
            // }
        },
        []
    )

    // on product-change
    useEffect(
        () => {
            if(product) {
                // set last-scanned products (remove the last one, if the item-count is too high
                let lastScannedProducts = JSON.parse(lastScanned);
                if(lastScannedProducts.unshift(product) > maxLastScannedProducts) {
                    lastScannedProducts.pop();
                }
                setLastScanned(JSON.stringify(lastScannedProducts));
            }
        },
        [product, setLastScanned]
    );

    // scanner should run
    useEffect(
        () => {
            async function startScanning() {
                // Do not initialize in these cases
                if('' === videoDeviceId) return
                if(0 === canvasWidth || 0 === canvasHeight) return
                if(!scannerShouldRun) return

                // reset
                setProduct(null);
                setNotFound(false);
                setError(null);

                // init config
                let config = JSON.parse(JSON.stringify(quaggaConfig));

                // determine video dimensions
                if(canvasWidth > 0 && canvasHeight > 0) {
                    config.inputStream.constraints.width = {ideal: canvasWidth};
                    config.inputStream.constraints.height = {ideal: canvasHeight};
                }

                // determine the number of workers
                //if(navigator.hardwareConcurrency) config.numOfWorkers = navigator.hardwareConcurrency;

                // set the device if it is selected
                if(videoDeviceId) config.inputStream.constraints.deviceId = {exact: videoDeviceId};

                // init quagga
                if(Quagga?.CameraAccess) await Quagga.stop()
                //setDebugMessage(JSON.stringify(config))
                await Quagga.init(
                    config,
                    async function (err) {
                        // error ?
                        if (err) {
                            setError(t('scan.error.no.camera', { ns: 'glossary' }));
                            //setError(err);
                            console.error(err);
                            await stopScan();
                        } else {
                            // no error, start quagga
                            Quagga.start();
                            let videoTrack = Quagga?.CameraAccess.getActiveTrack()
                            const capabilities = videoTrack?.getCapabilities ? videoTrack.getCapabilities() : {}
                            setTorchAvailable(!!capabilities.torch)
                        }
                    }
                );

                // code found?
                Quagga.onDetected(async function (result) {
                    // skip if the code is the same
                    if(code === result.codeResult.code) return;

                    // check error-rate and continue scanning if error-rate is too high
                    // https://github.com/serratus/quaggaJS/issues/237
                    let countDecodedCodes=0, err=0;
                    result.codeResult.decodedCodes.forEach(function(error) {
                        if (error.error!==undefined) {
                            countDecodedCodes++;
                            err+=parseFloat(error.error.toString());
                        }
                    });
                    // error-rate too high, skip = continue scanning
                    if (countDecodedCodes && ((err / countDecodedCodes) >= 0.1)) return;

                    // set new code
                    //result.codeResult.code = '4018077353758'; // uncomment for testing
                    setCode(result.codeResult.code);
                    setLoading(true);

                    // stop scanning
                    await stopScan();

                    // get the recaptcha-token
                    /*const recaptchaClient = new RecaptchaClient(process.env.recaptchaKey);
                    const token = await recaptchaClient.execute('/product/code');*/

                    // make the api-request
                    fetch('/api/product/code?locale=' + router.locale + '&code=' + encodeURIComponent(result.codeResult.code)/* + '&token=' + encodeURIComponent(token)*/)
                        .then(response => {
                            if (!response.ok) {
                                response.text().then(text => {
                                    throw new Error(text);
                                });
                            }
                            return response.json();
                        })
                        .then(data => {
                            if('ok' === data.header.status && data.body.product) {
                                // init product
                                setProduct({
                                    "id": data.body.product.id,
                                    "title": data.body.product.title,
                                    "link": '/product/' + data.body.product.id,
                                    "score": data.body.product.score,
                                    "nutrientScore": data.body.product.scores.nutrients,
                                    "ingredientScore": data.body.product.scores.ingredients,
                                    "ingredientTypes": data.body.product.ingredientTypes
                                });
                            } else {
                                setProduct(null);
                                setNotFound(true);
                            }

                            setLoading(false);
                        });
                });
            }
            startScanning()
        },
        [scannerShouldRun, videoDeviceId, canvasWidth, canvasHeight, router.locale]
    )

    // start scan callback
    function startScan() {
        setScannerShouldRun(true)
        setCanvasVisibility(true)
    }

    // stop scan callback
    async function stopScan() {
        setScannerShouldRun(false)
        setCanvasVisibility(false)
        await Quagga.stop()
    }

    // render
    return (
        <>
            {
                modal
                    ?
                    <>
                    <Modal
                        canvasVisible={canvasVisibility}
                        canvasWidth={canvasWidth}
                        canvasHeight={canvasHeight}
                        startScanHandler={startScan}
                        stopScanHandler={stopScan}
                        product={product}
                        loading={loading}
                        notFound={notFound}
                        error={error}
                        setVideoDeviceId={setVideoDeviceId}
                        torchAvailable={torchAvailable}
                    />
                    <Start
                        startScanHandler={startScan}
                        toggleModal={true}
                        background={background}
                    />
                    </>
                    :
                    <Page
                        canvasVisible={canvasVisibility}
                        canvasWidth={canvasWidth}
                        canvasHeight={canvasHeight}
                        startScanHandler={startScan}
                        stopScanHandler={stopScan}
                        product={product}
                        loading={loading}
                        notFound={notFound}
                        error={error}
                        lastScanned={lastScanned === LOCAL_STORAGE_STATUS.NOT_INITIALIZED ? [] : JSON.parse(lastScanned)}
                        setVideoDeviceId={setVideoDeviceId}
                        torchAvailable={torchAvailable}
                    />
            }
            {/*<Debug message={debugMessage} />*/}
        </>
    );
}