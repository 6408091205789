import React from "react";
import {useTranslation} from "next-i18next";

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
export default function Stop({ stopScanHandler, modal })
{
    /**
     *
     * @param e
     */
    function stopScan(e) {
        e.preventDefault();
        stopScanHandler();
    }

    // init
    const { t } = useTranslation();

    // render
    return(
        <>
            {/*<div className="w-auto">*/}
                {
                    modal
                    ?
                        <button className="form-control btn btn-outline-danger w-auto m-1" onClick={stopScan} data-bs-dismiss="modal">
                            <span className={'me-2'}>{t('stop')}</span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor"
                                 className="buttonIcon bi bi-slash-circle align-text-bottom" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                <path d="M11.354 4.646a.5.5 0 0 0-.708 0l-6 6a.5.5 0 0 0 .708.708l6-6a.5.5 0 0 0 0-.708z"/>
                            </svg>
                        </button>
                    :
                        <button className="form-control btn btn-outline-danger w-auto m-1" onClick={stopScan}>
                            <span className={'me-2'}>{t('stop')}</span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor"
                                 className="buttonIcon bi bi-slash-circle align-text-bottom" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                <path d="M11.354 4.646a.5.5 0 0 0-.708 0l-6 6a.5.5 0 0 0 .708.708l6-6a.5.5 0 0 0 0-.708z"/>
                            </svg>
                        </button>
                }
            {/*</div>*/}
        </>
    );
}